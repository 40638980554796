/* eslint-disable */
import Konva from 'konva';

export default class YNode extends Konva.Group{
    container = null;
    inputs = {};
    outputs = {};
    inputsInit = {};
    outputsInit = {};
    heightDefault = 60;
    widthDefault = 270;
    distanceOut = 0;
    distanceIn = 0;
    title = null;
    num_out = 0;
    num_in = 0;
    style = {
        icon: null,
        label: null,
        outText: null,
        outStyle: null,
        source: null,
        background: 'white'
    };
    context = {};
    events = {};

    constructor(id = null) {
        super({
            draggable: true,
        });

        if (id) {
            this._id = id;
        }
        this.setAttr('id', this._id);
    }

    render() {
        this.distanceOut = this.heightDefault / 2;
        this.distanceIn = this.heightDefault / 2;
        
        this.container = new Konva.Rect({
            x: 0,
            y: 0,
            width: this.widthDefault,
            height: this.heightDefault,
            fill: this.style.background ? this.style.background : 'white',
            stroke: '#E5E5E5',
            strokeWidth: 4,
            cornerRadius: 8,
        });
        
        for (var i = 1; i <= this.num_out; i++) {
            let name = `output_${i}_node_${this._id}`;
            var output = new Konva.Circle({
                name: name,
                x: this.container.width(),
                y: (this.distanceOut * i),
                radius: 10,
                fill: 'white',
                stroke: '#E5E5E5',
                strokeWidth: 4,
            });

            if (this.style.outText != undefined) {
                this.style.outText = Object.values(this.style.outText);
                if (this.style.outText[i - 1]) {
                    var titleOutPut = new Konva.Text({
                        x: this.container.width() - (output.width() + 17 + this.style.outText[i - 1].length),
                        y: (this.distanceOut * i) - 3,
                        text: this.style.outText[i - 1],
                        fontSize: 13,
                        fontFamily: 'Calibri',
                        fill: '#252525',
                        listening: false,
                    });
                    this.add(titleOutPut);
                }
            }

            if (this.style.outStyle != undefined) {
                this.style.outStyle = Object.values(this.style.outStyle);
                if (this.style.outStyle[i - 1]) {
                    output.setAttr('fill', this.style.outStyle[i - 1]);
                }
            }

            this.distanceOut = (this.heightDefault / this.num_out) - 15;
            let connections = this.outputsInit[`output_${i}`] ? this.outputsInit[`output_${i}`].connections : [];
            this.outputs[`output_${i}`] = {
                name,
                id: output._id,
                index: i,
                connections: connections,
            };
            output.setAttr('index', i);
            this.add(output);
            output.zIndex(1);
            output.on('mousedown', (e) => {
                this.dispatch('output_down', {
                    output: e.target,
                    node: this,
                });
            });
        }
        for (var i = 1; i <= this.num_in; i++) {
            let name = `input_${i}_node_${this._id}`;
            var input = new Konva.Circle({
                name: name,
                x: 0,
                y: (this.distanceIn * i),
                radius: 10,
                fill: 'white',
                stroke: '#E5E5E5',
                strokeWidth: 4,
            });
            this.distanceIn = (this.heightDefault / this.num_in) - 15;
            let connections = this.inputsInit[`input_${i}`] ? this.inputsInit[`input_${i}`].connections : [];
            this.inputs[`input_${i}`] = {
                id: input._id,
                index: i,
                name,
                connections: connections
            };
            input.setAttr('index', i);
            this.add(input);
            input.zIndex(1);
            input.on('mouseup', (e) => {
                this.dispatch('input_up', {
                    input: e.target,
                    node: this,
                });
            });
        }

        
        this.add(this.container);

        this.container.zIndex(0);

        this.on('mouseup', (e) => {
            this.dispatch('node_up', {
                node: e.target,
            });
        });

        this.on('click', (e) => {
            this.dispatch('node_click', {
                node: e.target,
            });
        });

        this.on('dragmove', (e) => {
            this.dispatch('node_drag', {
                node: e.target,
            });
        });

        this.container.on('mouseover', (e) => {
            this.dispatch('node_over', {
                node: e.target,
            });
        });
        this.container.on('mouseout', function (e) {
            e.target.attrs.stroke = '#E5E5E5';
            e.target.zIndex(0);
            e.target.draw();
        });
    }

    /* Events */
    event(event, callback) {
        // Check if the callback is not a function
        if (typeof callback !== 'function') {
            console.error(`The listener callback must be a function, the given type is ${typeof callback}`);
            return false;
        }
        // Check if the event is not a string
        if (typeof event !== 'string') {
            console.error(`The event name must be a string, the given type is ${typeof event}`);
            return false;
        }
        // Check if this event not exists
        if (this.events[event] === undefined) {
            this.events[event] = {
                listeners: [],
            };
        }
        this.events[event].listeners.push(callback);
    }

    dispatch(event, details) {
        // Check if this event not exists
        if (this.events[event] === undefined) {
            // console.error(`This event: ${event} does not exist`);
            return false;
        }
        this.events[event].listeners.forEach((listener) => {
            listener(details);
        });
    }
}